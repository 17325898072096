import React from 'react';

interface ButtonProps {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
}

const loadingSpinner = (
  <svg
    className="w-5 h-5 mr-3 text-white animate-spin"
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
  </svg>
);

const Button = ({ label, disabled, loading, onClick }: ButtonProps) => {
  return (
    <button
      className={`flex items-center justify-center w-full h-[36px] font-bold px-4 py-2 text-[14px] text-white rounded-3xl bg-wildfire-orange ${disabled || loading ? 'opacity-40' : ''}`}
      onClick={onClick}
    >
      {loading ? loadingSpinner : label}
    </button>
  );
};

export default Button;
