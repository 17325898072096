import React, { useEffect } from 'react';
import { Loader, Pagination } from 'semantic-ui-react';
import BonusPaymentsListTable from './BonusPaymentsListTable';
import BonusPaymentsTableDownload from './BonusPaymentsTableDownload';
import { AddBonusPaymentsData, BonusPayment, FormattedNetworkMerchantSearchResult } from './types';

interface BonusPaymentListProps {
  activePage: number;
  currentBonusPaymentsList: BonusPayment[];
  isListLoading: boolean;
  listError: string;
  totalPages: number;
  fetchBonusPaymentsList: () => void;
  setActivePage: (pageNumber: number) => void;
  updateBonusPayment: (args: { id: number; body: AddBonusPaymentsData }) => Promise<void>;
  fetchNetworkMerchantSearchResults: (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
  deleteBonusPayment: (id: number) => Promise<void>;
}

const BonusPaymentList = ({
  activePage,
  currentBonusPaymentsList,
  isListLoading,
  listError,
  totalPages,
  fetchBonusPaymentsList,
  setActivePage,
  updateBonusPayment,
  fetchNetworkMerchantSearchResults,
  deleteBonusPayment,
}: BonusPaymentListProps) => {
  const handlePaginationChange = (e: React.MouseEvent<HTMLAnchorElement>, { activePage }: any) => {
    setActivePage(activePage);
  };

  useEffect(() => {
    fetchBonusPaymentsList();
  }, [activePage]);

  const showEmptyListMessage = !Boolean(currentBonusPaymentsList.length) && !isListLoading && !Boolean(listError);
  const showErrorMessage = Boolean(listError) && !isListLoading;
  return (
    <div className="font-montserrat">
      <div className="flex items-center justify-between mb-4">
        <h2 className="m-0">Bonus Payments</h2>
        <div className="flex">
          <BonusPaymentsTableDownload tableData={currentBonusPaymentsList} />
          <Pagination
            activePage={activePage}
            onPageChange={handlePaginationChange}
            totalPages={totalPages}
            data-testid="bonus-payments-list-pagination"
          />
        </div>
      </div>
      <div className="p-5 overflow-x-scroll overflow-y-scroll bg-white rounded-md shadow-md">
        <BonusPaymentsListTable
          bonusPayments={currentBonusPaymentsList}
          fetchBonusPaymentsList={fetchBonusPaymentsList}
          updateBonusPayment={updateBonusPayment}
          fetchNetworkMerchantSearchResults={fetchNetworkMerchantSearchResults}
          deleteBonusPayment={deleteBonusPayment}
        />
        {showEmptyListMessage && (
          <div className="flex items-center justify-center w-full">
            <p>
              {totalPages === 1
                ? 'There are currently no bonus payments'
                : 'There is no bonus payments for selected page'}
            </p>
          </div>
        )}
        {showErrorMessage && (
          <div className="flex items-center justify-center w-full">
            <p className="text-red-600">{listError}</p>
          </div>
        )}
        {isListLoading && <Loader active size="massive" inline="centered" data-test-id="bonus payments list loader" />}
      </div>
    </div>
  );
};

export default BonusPaymentList;
