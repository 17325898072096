import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getBonusPaymentsList, updateBonusPayment, deleteBonusPayment } from 'actions/reconciles/reconciles';
import { getNetworkMerchantSearchResults } from 'actions/merchantAdmin';
import { findErrorMessage } from 'helpers';
import FindDeviceID from 'components/BonusPayments/FindDeviceID';
import BonusPaymentsList from 'components/BonusPayments/BonusPaymentsList';
import AddBonusPayments from 'components/BonusPayments/AddBonusPayments';
import {
  AddBonusPaymentsData,
  BonusPaymentsListResponse,
  BonusPayment,
  NetworkMerchantSearchResult,
  FormattedNetworkMerchantSearchResult,
} from 'components/BonusPayments/types';
import { objectKeysToLowerCase } from 'components/BonusPayments/helpers';
import './BonusPayments.scss';

interface BonusPaymentsProps {
  getBonusPaymentsList: (args: { pageNumber: number; status: string }) => Promise<BonusPaymentsListResponse>;
  updateBonusPayment: (args: { id: number; body: AddBonusPaymentsData }) => Promise<void>;
  getNetworkMerchantSearchResults: (search: string, networkId: number) => Promise<NetworkMerchantSearchResult[]>;
  deleteBonusPayment: (id: number) => Promise<void>;
}

const BonusPayments = ({
  getBonusPaymentsList,
  updateBonusPayment,
  deleteBonusPayment,
  getNetworkMerchantSearchResults,
}: BonusPaymentsProps) => {
  const [currentBonusPaymentsList, setCurrentBonusPaymentsList] = useState<BonusPayment[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isListLoading, setIsListLoading] = useState(false);
  const [listError, setListError] = useState('');

  const fetchBonusPaymentsList = async () => {
    try {
      setIsListLoading(true);
      const response = await getBonusPaymentsList({ pageNumber: activePage, status: 'PENDING' });

      setTotalPages(response.totalPages || 1);
      setCurrentBonusPaymentsList(response.bonusPayments || []);
    } catch (error) {
      setListError('Failed to retrieve bonus payments list');
      console.error(findErrorMessage(error));
    } finally {
      setIsListLoading(false);
    }
  };

  const fetchNetworkMerchantSearchResults = async (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => {
    if (searchQuery.length < 3) return;
    if (!networkId) return;

    setIsLoading(true);
    try {
      const results: NetworkMerchantSearchResult[] = await getNetworkMerchantSearchResults(
        encodeURIComponent(searchQuery),
        networkId,
      );
      const formattedResults: FormattedNetworkMerchantSearchResult[] = results.map(result =>
        objectKeysToLowerCase(result),
      );
      setSearchResults(formattedResults);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bonus-payments">
      <h1 className="bonus-payments-title">Bonus Payments</h1>
      <div className="flex flex-wrap justify-center gap-5 mt-6">
        <div>
          <FindDeviceID />
        </div>
        <div className="flex-grow min-w-[600px] max-w-[1080px]">
          <AddBonusPayments
            fetchBonusPaymentsList={fetchBonusPaymentsList}
            fetchNetworkMerchantSearchResults={fetchNetworkMerchantSearchResults}
          />
        </div>
      </div>
      <BonusPaymentsList
        activePage={activePage}
        currentBonusPaymentsList={currentBonusPaymentsList}
        totalPages={totalPages}
        isListLoading={isListLoading}
        listError={listError}
        fetchBonusPaymentsList={fetchBonusPaymentsList}
        setActivePage={setActivePage}
        updateBonusPayment={updateBonusPayment}
        fetchNetworkMerchantSearchResults={fetchNetworkMerchantSearchResults}
        deleteBonusPayment={deleteBonusPayment}
      />
    </div>
  );
};

const mapActionsToProp = {
  getBonusPaymentsList,
  updateBonusPayment,
  getNetworkMerchantSearchResults,
  deleteBonusPayment,
};

export default connect(null, mapActionsToProp)(BonusPayments);
