import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Table, Dimmer, Loader, Segment, Pagination, Dropdown } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import NetworkMerchantOnboardingModal from '../../components/NetworkMerchantOnboarding/NetworkMerchantOnboardingModal';
import { toPrettyTime } from '../../helpers';

const NetworkMerchantOnboarding = ({
  getNetworkMerchants,
  getNetworks,
  getNetworkMerchant,
  updateNetworkMerchant,
  getNetworkMerchantCommissions,
  getNetworkMerchantCommissionHistory,
  updateNetworkMerchantCommission,
  getNetworkMerchantKeywords,
  upsertNetworkMerchantKeyword,
  getMerchant,
  getCategoryOptions,
  getMerchantCategories,
  updateMerchantCategories,
  setLastUpdated,
  addMerchantImage,
  deleteMerchantImage,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();
  const initialPage = urlParams.get('page') || 1;
  const initialNetworkId = urlParams.get('networkId') || null;

  const [networkMerchantData, setNetworkMerchantData] = useState([]);
  const [networks, setNetworks] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(initialPage);
  const [pageInfo, setPageInfo] = useState({ TotalCount: 0, TotalPages: 0 });
  const [selectedNetworkId, setSelectedNetworkId] = useState(Number(initialNetworkId));

  const networkOptions = [
    { key: 'all', value: null, text: 'All' },
    ...networks.map(network => ({
      key: network.Name,
      value: network.ID,
      text: `${network.ID} - ${network.Name}`,
    })),
  ];

  useEffect(() => {
    // Update URL params when activePage or selectedNetworkId changes
    urlParams.set('page', activePage);
    if (selectedNetworkId) {
      urlParams.set('networkId', selectedNetworkId);
    } else {
      urlParams.delete('networkId');
    }

    history.push(`?${urlParams.toString()}`);

    const page = urlParams.get('page') || 1;
    const networkId = urlParams.get('networkId') || null;

    const fetchNetworkMerchantData = async () => {
      try {
        const networkMerchantsRes = await getNetworkMerchants({ networkId, page });
        const parsedData = JSON.parse(networkMerchantsRes?.body);
        setNetworkMerchantData(parsedData.Items);
        setPageInfo(parsedData.PageInfo);
      } catch (err) {
        if (err?.message?.includes("storage: object doesn't exist")) {
          setError(new Error('There are no network merchants to home.'));
        } else {
          setError(err);
        }
      }
      setIsLoading(false);
    };
    fetchNetworkMerchantData();
  }, [getNetworkMerchants, setNetworkMerchantData, selectedNetworkId, activePage]);

  useEffect(() => {
    const fetchNetworks = async () => {
      try {
        const networksRes = await getNetworks();
        const reformattedNetworks = {};
        networksRes.forEach(network => {
          reformattedNetworks[network.ID] = network.Name;
        });
        setNetworks(networksRes);
      } catch (err) {
        setError(err);
      }
    };
    fetchNetworks();
  }, [getNetworks, setNetworks]);

  const getNetworkNameById = networkID => {
    const currentNetwork = networks?.find(network => network.ID === networkID);
    return currentNetwork?.Name || '';
  };

  const generateTable = () => {
    if (isEmpty(networkMerchantData)) return <div className="text-center italic">No network merchants found</div>;

    return (
      <>
        <Table celled>
          <thead className="whitespace-nowrap bg-background-grey text-center text-sm xs:!hidden md:!table-header-group">
            <tr>
              <th>Name</th>
              <th>ID</th>
              <th>XID</th>
              <th>Network</th>
              <th>Created Date</th>
              <th>URL</th>
              <th>Admin Network Merchant</th>
            </tr>
          </thead>
          <tbody className="text-left text-sm whitespace-nowrap">
            {networkMerchantData?.slice(0, 500).map(({ ID, XID, Name, NetworkID, URL, CreatedDate }) => (
              <tr key={ID}>
                <td title={Name} className="truncate xs:max-w-[400px] md:max-w-[125px] whitespace-nowrap">
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">Name: </p>
                  {Name}
                </td>
                <td>
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">ID: </p>
                  {ID}
                </td>
                <td>
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">XID: </p>
                  {XID}
                </td>
                <td className="truncate xs:max-w-[400px] md:max-w-[125px]">
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">Network: </p>
                  {getNetworkNameById(NetworkID)}
                </td>
                <td>
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">Created Date: </p>
                  {toPrettyTime(CreatedDate, true)}
                </td>
                <td title={URL} className="truncate xs:max-w-[400px] md:max-w-[125px]">
                  <p className="xs:!inline xs:!whitespace-nowrap md:!hidden">URL: </p>
                  <a href={URL}>{URL}</a>
                </td>
                <td className="!text-center">
                  <NetworkMerchantOnboardingModal
                    ID={ID}
                    networks={networks}
                    getNetworkMerchant={getNetworkMerchant}
                    updateNetworkMerchant={updateNetworkMerchant}
                    getNetworkMerchantCommissions={getNetworkMerchantCommissions}
                    getNetworkMerchantCommissionHistory={getNetworkMerchantCommissionHistory}
                    updateNetworkMerchantCommission={updateNetworkMerchantCommission}
                    getNetworkMerchantKeywords={getNetworkMerchantKeywords}
                    upsertNetworkMerchantKeyword={upsertNetworkMerchantKeyword}
                    getMerchant={getMerchant}
                    getCategoryOptions={getCategoryOptions}
                    getMerchantCategories={getMerchantCategories}
                    updateMerchantCategories={updateMerchantCategories}
                    setLastUpdated={setLastUpdated}
                    addMerchantImage={addMerchantImage}
                    deleteMerchantImage={deleteMerchantImage}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </>
    );
  };

  return (
    <div className="w-full bg-background-grey font-montserrat">
      <h1 className="font-title text-center">Network Merchant Onboarding</h1>
      {!isEmpty(networkMerchantData) && (
        <h3 className="text-center italic mt-2 mb-8">Network Merchants: {pageInfo?.TotalCount}</h3>
      )}
      <div className="bg-white mt-8 p-5 rounded-md shadow-md shadow-[5px_5px_10px_-3px_#a8a8a8]">
        {error && (
          <div>
            <p>{error.message}</p>
          </div>
        )}
        {isLoading ? (
          <Segment style={{ border: 'none', boxShadow: 'none', marginTop: '50px' }}>
            <Dimmer active inverted>
              <Loader>Loading</Loader>
            </Dimmer>
          </Segment>
        ) : (
          <>
            <div className="flex justify-between mb-5 xs:flex-wrap xs:gap-5">
              <Dropdown
                basic
                placeholder="Select Network"
                search
                selection
                onChange={(_, { value }) => {
                  setSelectedNetworkId(value);
                  setActivePage(1);
                }}
                value={selectedNetworkId}
                options={networkOptions}
                icon={<i className="dropdown icon text-wildfire-orange" />}
              />
              <Pagination
                className="overflow-auto"
                activePage={activePage}
                onPageChange={(_, { activePage }) => setActivePage(activePage)}
                totalPages={pageInfo?.TotalPages}
              />
            </div>
            <div className="max-w-[96vw] overflow-auto">{generateTable()}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default NetworkMerchantOnboarding;
