import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Icon, Search as SearchUI, TableCell, TableRow } from 'semantic-ui-react';
import { debounce } from 'lodash';
import { wfUserAgent } from 'helpers';
import ChangeIcon from 'components/icons/ChangeIcon';
import InputV2 from 'components/Input/InputV2';
import TruncatedCopyCell from 'components/TruncatedCopyCell/TruncatedCopyCell';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { AddBonusPaymentsData, BonusPayment, BonusPaymentsTableColumns, UpdateBonusPaymentsFormValues } from './types';
import { currencyOptions, networkOptions, tableColumns } from './constants';
import { generateCommissionAmount } from './helpers';
import { FormattedNetworkMerchantSearchResult } from './types';

const BasicCell = ({ value }: { value: any }) => (
  <TableCell singleLine className="min-w-[100px]">
    <span>{value}</span>
  </TableCell>
);

const CurrencyCell = ({ amount, currency }: { amount: string; currency: string }) => (
  <TableCell singleLine>
    <span>{`${parseFloat(amount.toString()).toFixed(2)} ${currency}`}</span>
  </TableCell>
);

const EditableInput = ({
  id,
  value,
  field,
  type = 'text',
  handleChange,
}: {
  id: string;
  value: string | number;
  field: keyof UpdateBonusPaymentsFormValues;
  type?: 'text' | 'number' | 'date';
  handleChange: (key: keyof UpdateBonusPaymentsFormValues, value: number | string) => void;
}) => (
  <TableCell singleLine>
    <InputV2
      className="min-w-[100px] !mb-0"
      id={id}
      label=""
      type={type}
      value={value.toString()}
      onChange={value => handleChange(field, value)}
    />
  </TableCell>
);

const EditableCommission = ({
  id,
  rateValue,
  amountValue,
  type = 'text',
  setCommissionRate,
  handleChange,
}: {
  id: string;
  rateValue: number;
  amountValue: number;
  type?: 'text' | 'number' | 'date';
  setCommissionRate: (value: number) => void;
  handleChange: (key: keyof UpdateBonusPaymentsFormValues, value: number | string) => void;
}) => (
  <TableCell singleLine>
    <div className="flex items-stretch h-full">
      <InputV2
        className="pr-1 min-w-[100px] !mb-0"
        placeholder="%"
        id={id}
        label=""
        title="Commission Rate"
        error={rateValue < 0 || rateValue > 100}
        errorMessage="Commission rate must be between 0 and 100"
        type={type}
        value={rateValue.toString()}
        onChange={value => setCommissionRate(Number(value))}
      />
      <InputV2
        className="min-w-[100px] !mb-0"
        id={id}
        label=""
        title="Commission Amount"
        type={type}
        value={amountValue.toString()}
        onChange={value => handleChange('commissionAmount', value)}
      />
    </div>
  </TableCell>
);

const EditableNetwork = ({
  value,
  formValues,
  setNetworkMerchantError,
  setFormValues,
  setNetworkId,
}: {
  value: string;
  formValues: UpdateBonusPaymentsFormValues;
  setNetworkMerchantError: (error: string) => void;
  setFormValues: (values: UpdateBonusPaymentsFormValues) => void;
  setNetworkId: (id: number) => void;
}) => (
  <TableCell singleLine>
    <Autocomplete
      className="min-w-[200px] !mb-0"
      label=""
      id="network-id-input"
      value={value}
      onOptionClick={value => {
        if (typeof value === 'string') return;
        setNetworkMerchantError('');
        setNetworkId(value);
      }}
      onClearInput={() => {
        setFormValues({ ...formValues, networkMerchantId: 0 });
        setNetworkId(0);
      }}
      options={networkOptions}
    />
  </TableCell>
);

const EditableCurrencyInput = ({
  value,
  saleCurrency,
  setSaleAmount,
  handleChange,
}: {
  value: string | number;
  saleCurrency: string;
  setSaleAmount: (value: number) => void;
  handleChange: (key: keyof UpdateBonusPaymentsFormValues, value: number | string) => void;
}) => (
  <TableCell singleLine>
    <div className="flex items-stretch h-full">
      <InputV2
        className="pr-1 min-w-[80px] !mb-0"
        id={`sale-amount-input`}
        label=""
        value={value.toString()}
        onChange={value => setSaleAmount(Number(value))}
      />
      <Autocomplete
        className="min-w-[200px] !mb-0"
        label=""
        id={`sale-amount-currency-input`}
        value={saleCurrency}
        onOptionClick={value => handleChange('saleCurrencyCode', value)}
        options={currencyOptions}
      />
    </div>
  </TableCell>
);

interface BonusPaymentsListTableRowProps {
  bonusPayment: BonusPayment;
  editingMode: number | null;
  fetchBonusPaymentsList: () => void;
  updateBonusPayment: (args: { id: number; body: AddBonusPaymentsData }) => Promise<void>;
  fetchNetworkMerchantSearchResults: (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
  deleteBonusPayment: (id: number) => Promise<void>;
  setEditingMode: (value: number | null) => void;
}

const BonusPaymentsListTableRow = ({
  bonusPayment,
  editingMode,
  fetchBonusPaymentsList,
  updateBonusPayment,
  fetchNetworkMerchantSearchResults,
  deleteBonusPayment,
  setEditingMode,
}: BonusPaymentsListTableRowProps) => {
  const defaultFormValues = {
    networkMerchantId: bonusPayment.networkMerchantId,
    saleCurrencyCode: bonusPayment.saleCurrencyCode,
    commissionAmount: Number(bonusPayment.commissionAmount),
    eventDate: bonusPayment.eventDate,
    merchantOrderId: bonusPayment.merchantOrderId,
    deviceId: bonusPayment.deviceId,
    trackingCode: bonusPayment.trackingCode,
  };

  const [formValues, setFormValues] = useState<UpdateBonusPaymentsFormValues>(defaultFormValues);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<FormattedNetworkMerchantSearchResult[]>([]);
  const [selectedNetworkMerchantName, setSelectedNetworkMerchantName] = useState(bonusPayment.networkMerchantName);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saleAmount, setSaleAmount] = useState(Number(bonusPayment.saleAmount));
  const [commissionRate, setCommissionRate] = useState(0);
  const [networkMerchantError, setNetworkMerchantError] = useState('');
  const [networkId, setNetworkId] = useState(bonusPayment.networkId);

  const hasFormChanged =
    saleAmount !== Number(bonusPayment.saleAmount) ||
    formValues.saleCurrencyCode !== defaultFormValues.saleCurrencyCode ||
    formValues.commissionAmount !== defaultFormValues.commissionAmount ||
    formValues.eventDate !== defaultFormValues.eventDate ||
    formValues.merchantOrderId !== defaultFormValues.merchantOrderId ||
    formValues.trackingCode !== defaultFormValues.trackingCode ||
    formValues.deviceId !== defaultFormValues.deviceId ||
    networkId !== bonusPayment.networkId ||
    formValues.networkMerchantId !== defaultFormValues.networkMerchantId;

  const isValidUpdate = () => {
    if ((commissionRate && Number(commissionRate) < 0) || Number(commissionRate) > 100) {
      return false;
    }
    return true;
  };

  const handleOnChange = (key: keyof UpdateBonusPaymentsFormValues, value: number | string) => {
    setFormValues({ ...formValues, [key]: value });
  };

  const onSetSearchQuery = useCallback(
    debounce((searchQuery: string): void => {
      fetchNetworkMerchantSearchResults(searchQuery, networkId, setSearchResults, setIsLoading);
    }, 500),
    [networkId],
  );

  const renderSearchResults = ({ resourceid, name, disabled }: any) => {
    return <p className={disabled ? 'pointer-events-none opacity-50' : ''}>{`${resourceid} - ${name}`}</p>;
  };

  const handleChangeNetworkMerchant = (id: number, name: string, disabled: boolean) => {
    if (disabled) return;

    setFormValues({ ...formValues, networkMerchantId: id });
    setSelectedNetworkMerchantName(name);
    setSearchResults([]);
    setSearchQuery('');
  };

  const handleCancelEdit = () => {
    setFormValues(defaultFormValues);
    setEditingMode(null);
  };

  const handleConfirmEdit = async () => {
    if (!isValidUpdate()) {
      toast.error('Please make sure your edits are valid and try again');
      return;
    }
    const bonusPaymentData: AddBonusPaymentsData = {
      CommissionAmount: Number(formValues.commissionAmount),
      DeviceID: Number(formValues.deviceId),
      EventDate: new Date(formValues.eventDate).toISOString(),
      MerchantOrderID: formValues.merchantOrderId,
      ModifiedAuthor: localStorage.getItem('userEmail') || wfUserAgent(),
      NetworkID: networkId,
      NetworkMerchantID: Number(formValues.networkMerchantId),
      SaleAmount: Number(saleAmount),
      SaleCurrencyCode: formValues.saleCurrencyCode,
      TrackingCode: formValues.trackingCode,
    } as AddBonusPaymentsData;

    try {
      await updateBonusPayment({ id: bonusPayment.id, body: bonusPaymentData });
      setEditingMode(null);
      fetchBonusPaymentsList();
      toast.success('Successfully updated bonus payment');
    } catch (error) {
      console.error(error);
      toast.error('Please make sure your values are valid and try again');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteBonusPayment(bonusPayment.id);
      fetchBonusPaymentsList();
      toast.success('Successfully deleted bonus payment');
    } catch (error) {
      console.error(error);
      toast.error('Failed to delete bonus payment. Please try again');
    }
  };

  useEffect(() => {
    if (!saleAmount || !commissionRate) return;

    const commissionAmount = generateCommissionAmount(commissionRate, saleAmount);
    setFormValues({ ...formValues, commissionAmount });
  }, [commissionRate, saleAmount]);

  const rowCells = tableColumns.map((tableColumn: BonusPaymentsTableColumns) => {
    switch (tableColumn.key) {
      case 'network':
        return editingMode === bonusPayment.id ? (
          <EditableNetwork
            value={`${bonusPayment.networkId} - ${bonusPayment.networkName}`}
            formValues={formValues}
            setNetworkMerchantError={setNetworkMerchantError}
            setFormValues={setFormValues}
            setNetworkId={setNetworkId}
          />
        ) : (
          <BasicCell value={`${bonusPayment.networkId} - ${bonusPayment.networkName}`} />
        );

      case 'networkMerchant':
        return editingMode === bonusPayment.id ? (
          <TableCell singleLine className="min-w-[200px]">
            <SearchUI
              fluid
              input={{
                fluid: true,
                placeholder:
                  formValues.networkMerchantId === 0 || networkId === 0
                    ? ''
                    : `${formValues.networkMerchantId} - ${selectedNetworkMerchantName}`,
                iconPosition: 'left',
              }}
              loading={isLoading}
              onResultSelect={(_: any, data: any) => {
                handleChangeNetworkMerchant(data.result.id, data.result.name, data.result.disabled);
              }}
              onSearchChange={(event: any): void => {
                event.persist();
                if (networkId === 0) {
                  setNetworkMerchantError('Select a network');
                  return;
                }
                setSearchQuery(event.target.value);
                onSetSearchQuery(event.target.value);
              }}
              results={searchResults}
              value={searchQuery}
              resultRenderer={renderSearchResults}
              open={searchResults.length > 0}
              onBlur={() => {
                setSearchResults([]);
                setSearchQuery('');
              }}
              noResultsMessage="No options found."
            />
            {!!networkMerchantError && <p className="text-red-500 ">{networkMerchantError}</p>}
          </TableCell>
        ) : (
          <BasicCell value={`${bonusPayment.networkMerchantId} - ${bonusPayment.networkMerchantName}`} />
        );

      case 'modifiedDate':
      case 'createdDate':
        return <BasicCell value={bonusPayment[tableColumn.key].split('T')[0]} />;

      case 'eventDate': {
        return editingMode === bonusPayment.id ? (
          <EditableInput
            id="event-date-input"
            value={formValues.eventDate.split('T')[0]}
            field="eventDate"
            type="date"
            handleChange={handleOnChange}
          />
        ) : (
          <BasicCell value={bonusPayment[tableColumn.key].split('T')[0]} />
        );
      }

      case 'deviceId': {
        return editingMode === bonusPayment.id ? (
          <EditableInput
            id="device-id-input"
            value={formValues.deviceId}
            field="deviceId"
            handleChange={handleOnChange}
          />
        ) : (
          <BasicCell value={bonusPayment[tableColumn.key]} />
        );
      }

      case 'merchantOrderId': {
        return editingMode === bonusPayment.id ? (
          <EditableInput
            id="merchant-order-id-input"
            value={formValues.merchantOrderId}
            field="merchantOrderId"
            handleChange={handleOnChange}
          />
        ) : (
          <BasicCell value={bonusPayment[tableColumn.key]} />
        );
      }

      case 'saleAmount': {
        return editingMode === bonusPayment.id ? (
          <EditableCurrencyInput
            value={saleAmount}
            saleCurrency={formValues.saleCurrencyCode}
            setSaleAmount={setSaleAmount}
            handleChange={handleOnChange}
          />
        ) : (
          <CurrencyCell amount={bonusPayment[tableColumn.key]} currency={bonusPayment.saleCurrencyCode} />
        );
      }

      case 'commissionAmount': {
        return editingMode === bonusPayment.id ? (
          <>
            <EditableCommission
              id="commission-amount-input"
              rateValue={commissionRate}
              amountValue={formValues.commissionAmount}
              setCommissionRate={setCommissionRate}
              handleChange={handleOnChange}
            />
          </>
        ) : (
          <CurrencyCell amount={bonusPayment[tableColumn.key]} currency={bonusPayment.applicationCurrencyCode} />
        );
      }

      case 'bonusAmount': {
        return <CurrencyCell amount={bonusPayment[tableColumn.key]} currency={bonusPayment.applicationCurrencyCode} />;
      }

      case 'usdBonusAmount': {
        return <CurrencyCell amount={bonusPayment[tableColumn.key]} currency="USD" />;
      }

      case 'trackingCode': {
        return editingMode === bonusPayment.id ? (
          <EditableInput
            id="tracking-code-input"
            value={formValues.trackingCode}
            field="trackingCode"
            handleChange={handleOnChange}
          />
        ) : (
          <TruncatedCopyCell text={bonusPayment[tableColumn.key].toString()} />
        );
      }

      default: {
        return <BasicCell value={bonusPayment[tableColumn.key]} />;
      }
    }
  });

  // Actions column
  rowCells.push(
    <TableCell>
      <div className="flex justify-center gap-2">
        {editingMode === bonusPayment.id ? (
          <div className="flex justify-center gap-2">
            <button
              onClick={handleConfirmEdit}
              disabled={!hasFormChanged}
              className={`text-sm rounded-md px-3 py-2 ${
                hasFormChanged
                  ? 'bg-green-500 text-white hover:bg-green-600'
                  : 'bg-gray-300 text-gray-500 cursor-not-allowed'
              }`}
            >
              CONFIRM
            </button>
            <button
              onClick={handleCancelEdit}
              className="text-sm bg-red-500 text-white hover:bg-red-600 rounded-md px-3 py-2"
            >
              CANCEL
            </button>
          </div>
        ) : (
          <>
            <button
              title="Edit"
              onClick={() => {
                setEditingMode(bonusPayment.id);
              }}
              className="text-xs text-white bg-wildfire-orange hover:text-black rounded-full p-2"
            >
              <ChangeIcon />
            </button>
            <button
              title="Delete"
              onClick={() => setConfirmationModalOpen(true)}
              className="text-xs text-white bg-wildfire-orange hover:text-black rounded-full p-2 w-[40px] h-[40px] "
            >
              <Icon className="!flex items-center justify-center !w-[25px] !text-base" name="delete" />
            </button>
          </>
        )}
      </div>
    </TableCell>,
  );

  return (
    <>
      <TableRow key={bonusPayment.id}>
        {rowCells.map((cell, index) => React.cloneElement(cell, { key: index }))}
      </TableRow>
      <ConfirmationModal
        action="delete the bonus payment"
        modalOpen={confirmationModalOpen}
        setModalOpen={setConfirmationModalOpen}
        onConfirm={handleDelete}
      />
    </>
  );
};

export default BonusPaymentsListTableRow;
