import React, { useState } from 'react';
import { Table, TableBody, TableHeaderCell } from 'semantic-ui-react';
import { AddBonusPaymentsData, BonusPayment, BonusPaymentsTableColumns } from './types';
import BonusPaymentsListTableRow from './BonusPaymentsListTableRow';
import { tableColumns } from './constants';
import { FormattedNetworkMerchantSearchResult } from './types';
import './BonusPaymentsListTable.scss';

interface BonusPaymentListTableProps {
  bonusPayments: BonusPayment[];
  fetchBonusPaymentsList: () => void;
  updateBonusPayment: (args: { id: number; body: AddBonusPaymentsData }) => Promise<void>;
  fetchNetworkMerchantSearchResults: (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
  deleteBonusPayment: (id: number) => Promise<void>;
}

const BonusPaymentsListTable = ({
  bonusPayments,
  fetchBonusPaymentsList,
  updateBonusPayment,
  fetchNetworkMerchantSearchResults,
  deleteBonusPayment,
}: BonusPaymentListTableProps) => {
  const [editingMode, setEditingMode] = useState<number | null>(null);

  const renderTableBody = () => {
    if (!bonusPayments.length) {
      return <></>;
    }

    return (
      <TableBody>
        {bonusPayments.map(bonusPayment => (
          <BonusPaymentsListTableRow
            key={bonusPayment.id}
            bonusPayment={bonusPayment}
            fetchBonusPaymentsList={fetchBonusPaymentsList}
            updateBonusPayment={updateBonusPayment}
            fetchNetworkMerchantSearchResults={fetchNetworkMerchantSearchResults}
            deleteBonusPayment={deleteBonusPayment}
            editingMode={editingMode}
            setEditingMode={setEditingMode}
          />
        ))}
      </TableBody>
    );
  };

  return (
    <Table compact celled striped aria-label="bonus payments table" className="bonus-payments-list-table">
      <thead>
        <tr>
          {tableColumns.map((tableColumn: BonusPaymentsTableColumns) => {
            if (tableColumn.key === 'commissionAmount') {
              return (
                <TableHeaderCell singleLine>
                  {editingMode ? 'Commission Rate/Amount' : 'Commission Amount'}
                </TableHeaderCell>
              );
            }

            return <TableHeaderCell key={tableColumn.key}>{tableColumn.columnName}</TableHeaderCell>;
          })}
          <TableHeaderCell singleLine>Actions</TableHeaderCell>
        </tr>
      </thead>
      {renderTableBody()}
    </Table>
  );
};

export default BonusPaymentsListTable;
