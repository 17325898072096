import React, { useState } from 'react';
import { TableCell, Icon } from 'semantic-ui-react';
import { truncate } from 'lodash';

interface TruncatedCopyCellProps {
  text: string;
}

const TruncatedCopyCell = ({ text }: TruncatedCopyCellProps) => {
  const [wasCopiedRecently, setWasCopiedRecently] = useState(false);
  const handleCopy = () => {
    setWasCopiedRecently(true);
    navigator.clipboard.writeText(text);
    setTimeout(() => setWasCopiedRecently(false), 2000);
  };
  return (
    <TableCell
      singleLine
      style={{ cursor: 'pointer' }}
      selectable
      className="truncated-table-cell"
      onClick={handleCopy}
    >
      <Icon name={wasCopiedRecently ? 'check' : 'copy'} color={wasCopiedRecently ? 'green' : 'grey'} />
      {truncate(text, { length: 20 })}
    </TableCell>
  );
};

export default TruncatedCopyCell;
