import React, { useCallback, useEffect, useState } from 'react';
import { debounce, startCase } from 'lodash';
import InputV2 from 'components/Input/InputV2';
import Button from 'components/Button/Button';
import { Search as SearchUI } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { format, utcToZonedTime } from 'date-fns-tz';
import Autocomplete from 'components/Autocomplete/Autocomplete';
import { addBonusPayment } from 'actions/reconciles/reconciles';
import { connect } from 'react-redux';
import { wfUserAgent } from 'helpers';
import { AddBonusPaymentsData, FormattedNetworkMerchantSearchResult } from './types';
import { currencyOptions, networkOptions } from './constants';
import { generateCommissionAmount } from './helpers';

interface AddBonusPaymentsFormValues {
  commissionAmount: number;
  deviceId: string;
  eventDate: string;
  merchantOrderId: string;
  networkMerchantId: number;
  saleCurrencyCode: string;
  trackingCode: string;
}

type FormErrors = Partial<
  Record<keyof AddBonusPaymentsFormValues | 'saleAmount' | 'commissionRate' | 'networkId', string>
>;

const defaultAddBonusPaymentsForm = {
  commissionAmount: 0,
  deviceId: '',
  eventDate: '',
  merchantOrderId: '',
  networkMerchantId: 0,
  saleCurrencyCode: '',
  trackingCode: '',
};

interface AddBonusPaymentsFormProps {
  addBonusPayment: (data: AddBonusPaymentsData) => Promise<void>;
  fetchBonusPaymentsList: () => void;
  fetchNetworkMerchantSearchResults: (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
}

const AddBonusPaymentsForm = ({
  addBonusPayment,
  fetchBonusPaymentsList,
  fetchNetworkMerchantSearchResults,
}: AddBonusPaymentsFormProps) => {
  const [formValues, setFormValues] = useState<AddBonusPaymentsFormValues>(defaultAddBonusPaymentsForm);
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isCreatingBonusPayment, setIsCreatingBonusPayment] = useState(false);
  const [saleAmount, setSaleAmount] = useState(0);
  const [commissionRate, setCommissionRate] = useState(0);
  const [selectedNetworkMerchantName, setSelectedNetworkMerchantName] = useState('');
  const [searchResults, setSearchResults] = useState<FormattedNetworkMerchantSearchResult[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [networkId, setNetworkId] = useState(0);

  const isValidBonusPaymentForm = (): boolean => {
    const newErrors: FormErrors = {};
    Object.entries(formValues).forEach(([key, value]) => {
      if (typeof value === 'string' && !value.trim()) {
        newErrors[key as keyof FormErrors] = `${startCase(key)} is required`;
      } else if (typeof value === 'number' && value <= 0) {
        newErrors[key as keyof FormErrors] = `${startCase(key)} is required`;
      }
    });

    if (commissionRate > 100) {
      newErrors.commissionRate = 'Commission Rate must be less than or equal to 100';
    }

    if (saleAmount <= 0) {
      newErrors.saleAmount = 'Sale Amount is required';
    }

    setFormErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!isValidBonusPaymentForm()) return;

    try {
      const {
        commissionAmount,
        deviceId,
        eventDate,
        merchantOrderId,
        networkMerchantId,
        saleCurrencyCode,
        trackingCode,
      } = formValues;

      if (!commissionAmount || !deviceId || !eventDate || !merchantOrderId || !networkId || !networkMerchantId) return;

      setIsCreatingBonusPayment(true);
      await addBonusPayment({
        CommissionAmount: Number(commissionAmount),
        DeviceID: Number(deviceId),
        EventDate: format(utcToZonedTime(eventDate, 'UTC'), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        MerchantOrderID: merchantOrderId,
        ModifiedAuthor: localStorage.getItem('userEmail') || wfUserAgent(),
        NetworkID: Number(networkId),
        NetworkMerchantID: networkMerchantId,
        SaleAmount: Number(saleAmount),
        SaleCurrencyCode: saleCurrencyCode,
        TrackingCode: trackingCode,
      });

      setFormValues(defaultAddBonusPaymentsForm);
      setSaleAmount(0);
      setCommissionRate(0);
      setSelectedNetworkMerchantName('');
      toast.success('Successfully created new bonus payment!');
      fetchBonusPaymentsList();
    } catch (error) {
      console.error(error);
      toast.error('Failed to create new bonus payment');
    } finally {
      setIsCreatingBonusPayment(false);
    }
  };

  const handleOnChange = (key: keyof AddBonusPaymentsFormValues, value: number | string) => {
    if (key === 'commissionAmount') {
      setFormValues({ ...formValues, [key]: Number(value) });
    } else {
      setFormValues({ ...formValues, [key]: value });
    }

    if (key in formErrors && value) {
      setFormErrors({ ...formErrors, [key]: '' });
    }
  };

  const handleChangeNetworkMerchant = (id: number, name: string, disabled: boolean) => {
    if (disabled) return;

    setFormValues({ ...formValues, networkMerchantId: id });
    setSelectedNetworkMerchantName(name);
    setSearchResults([]);
    setSearchQuery('');
  };

  const onSetSearchQuery = useCallback(
    debounce((searchQuery: string): void => {
      fetchNetworkMerchantSearchResults(searchQuery, Number(networkId), setSearchResults, setIsLoading);
    }, 500),
    [networkId],
  );

  const renderSearchResults = ({ resourceid, name, disabled }: any) => {
    return <p className={disabled ? 'pointer-events-none opacity-50' : ''}>{`${resourceid} - ${name}`}</p>;
  };

  useEffect(() => {
    if (!saleAmount || !commissionRate) {
      setFormValues({ ...formValues, commissionAmount: 0 });
      return;
    }

    const commissionAmount = generateCommissionAmount(commissionRate, saleAmount);
    setFormValues({ ...formValues, commissionAmount });
  }, [commissionRate, saleAmount]);

  return (
    <div className="flex justify-center flex-item flex-col w-full p-4 bg-white rounded-md shadow-md mb-[15px] grow">
      <div className="flex flex-row">
        <div className="w-1/3 m-2">
          <Autocomplete
            className="min-w-[200px] mb-[10px]"
            label="Network"
            id="network-id-input"
            error={!!formErrors.networkId}
            errorMessage={formErrors.networkId}
            value={networkOptions.find(option => option.value === Number(networkId))?.label || ''}
            onOptionClick={(value: string | number) => {
              if (typeof value === 'number') {
                setNetworkId(value);
                setFormErrors({ ...formErrors, networkId: '' });
              }
            }}
            options={networkOptions}
            onClearInput={() => setNetworkId(0)}
          />
          <div className="mb-[8px]">
            <label htmlFor="network-id-input" className="mb-[5px] text-[13px] font-bold ">
              Network Merchant
            </label>
            <SearchUI
              id="network-id-input"
              fluid
              input={{
                fluid: true,
                placeholder:
                  formValues.networkMerchantId === 0 || networkId === 0
                    ? ''
                    : `${formValues.networkMerchantId} - ${selectedNetworkMerchantName}`,
                iconPosition: 'left',
              }}
              loading={isLoading}
              onResultSelect={(_: any, data: any) => {
                handleChangeNetworkMerchant(data.result.id, data.result.name, data.result.disabled);
              }}
              onSearchChange={(event: any): void => {
                event.persist();
                if (networkId === 0) {
                  setFormErrors({ ...formErrors, networkMerchantId: 'Select a network' });
                  return;
                }
                setSearchQuery(event.target.value);
                onSetSearchQuery(event.target.value);
              }}
              results={searchResults}
              value={searchQuery}
              resultRenderer={renderSearchResults}
              open={searchResults.length > 0}
              onBlur={() => {
                setSearchResults([]);
                setSearchQuery('');
              }}
              noResultsMessage="No options found."
            />
            {(!!formErrors.networkMerchantId || !!formErrors.networkId) && (
              <p className="text-red-500 ">{formErrors.networkMerchantId}</p>
            )}
          </div>
          <InputV2
            id="event-date-input"
            label="Event Date"
            type="date"
            value={formValues.eventDate}
            error={!!formErrors.eventDate}
            errorMessage={formErrors.eventDate}
            onChange={(value: number | string) => handleOnChange('eventDate', value)}
          />
        </div>
        <div className="w-1/3 m-2">
          <InputV2
            id="sale-amount-input"
            label="Sale Amount"
            type="number"
            value={saleAmount.toString()}
            error={!!formErrors.saleAmount}
            errorMessage={formErrors.saleAmount}
            onChange={(value: string) => {
              setSaleAmount(Number(value));
              setFormErrors({ ...formErrors, saleAmount: '' });
            }}
          />
          <InputV2
            id="commission-rate-input"
            label="Commission Rate (%)"
            type="number"
            value={commissionRate.toString()}
            max={100}
            error={!!formErrors.commissionRate}
            errorMessage={formErrors.commissionRate}
            onChange={(value: string) => {
              setCommissionRate(Number(value));
              setFormErrors({ ...formErrors, commissionRate: '' });
            }}
          />
          <InputV2
            id="commission-amount-input"
            label="Commission Amount"
            type="number"
            value={formValues.commissionAmount.toString()}
            error={!!formErrors.commissionAmount}
            errorMessage={formErrors.commissionAmount}
            onChange={(value: number | string) => handleOnChange('commissionAmount', value)}
          />
          <Autocomplete
            className="min-w-[80px]"
            label="Sale Currency Code"
            id="sale-currency-code-autocomplete"
            error={!!formErrors.saleCurrencyCode}
            errorMessage={formErrors.saleCurrencyCode}
            value={formValues.saleCurrencyCode}
            onOptionClick={(value: string | number) => {
              if (typeof value === 'string') {
                handleOnChange('saleCurrencyCode', value);
              }
            }}
            options={currencyOptions}
          />
        </div>
        <div className="flex flex-col w-1/3 m-2">
          <InputV2
            id="merchant-order-id-input"
            label="Merchant Order ID"
            error={!!formErrors.merchantOrderId}
            errorMessage={formErrors.merchantOrderId}
            value={formValues.merchantOrderId}
            onChange={(value: number | string) => handleOnChange('merchantOrderId', value)}
          />
          <InputV2
            id="tracking-code-input"
            label="Tracking Code"
            value={formValues.trackingCode}
            error={!!formErrors.trackingCode}
            errorMessage={formErrors.trackingCode}
            onChange={(value: number | string) => handleOnChange('trackingCode', value)}
          />
          <InputV2
            id="device-id-input"
            label="Device ID"
            value={formValues.deviceId}
            error={!!formErrors.deviceId}
            errorMessage={formErrors.deviceId}
            onChange={(value: number | string) => handleOnChange('deviceId', value)}
          />
        </div>
      </div>
      <div className="flex justify-center py-4">
        <div className="w-[200px]">
          <Button label="Submit" loading={isCreatingBonusPayment} onClick={() => handleSubmit()} />
        </div>
      </div>
    </div>
  );
};

const mapActionsToProp = {
  addBonusPayment,
};
export default connect(null, mapActionsToProp)(AddBonusPaymentsForm);
