import { wfGet } from '..';

export const getNetworkMerchants = args => async dispatch => {
  const { networkId, page } = args;
  const qs = networkId ? `?page=${page}&networkId=${networkId}` : `?page=${page}`;

  try {
    const networkMerchants = await dispatch(wfGet(`/api/network-merchant-onboarding${qs}`));
    return networkMerchants;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getNetworks = () => async dispatch => {
  try {
    const networks = await dispatch(wfGet(`/api/networks`));
    return networks;
  } catch (error) {
    return Promise.reject(error);
  }
};
