import React, { useState, useEffect } from 'react';

export interface AutocompleteOption {
  label: string;
  value: any;
}

interface AutocompleteProps {
  className?: string;
  options: AutocompleteOption[];
  label: string;
  onOptionClick: (value: string | number) => void;
  id: string;
  value: string;
  disabled?: boolean;
  error?: boolean;
  errorMessage?: string;
  placeholder?: string;
  onClearInput?: () => void;
}

const Autocomplete = ({
  className = '',
  options,
  label,
  id,
  error,
  errorMessage,
  placeholder,
  disabled,
  value,
  onOptionClick,
  onClearInput,
}: AutocompleteProps) => {
  const [filteredOptions, setFilteredOptions] = useState<AutocompleteOption[]>(
    options.filter(option => option.value === value),
  );
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [userInput, setUserInput] = useState(value || '');

  useEffect(() => {
    setUserInput(value);
  }, [value]);

  const handleOnChange = (e: any) => {
    const input = e.target.value;
    const filtered = input
      ? options.filter(option => option.label.toLowerCase().includes(input.toLowerCase()))
      : [...options];

    setUserInput(input);
    setFilteredOptions(filtered);
    setShowSuggestions(true);
  };

  const onClick = (option: AutocompleteOption) => {
    setUserInput(option.label);
    onOptionClick(option.value);
    setFilteredOptions([]);
    setShowSuggestions(false);
  };

  const clearInput = () => {
    setUserInput('');
    setFilteredOptions([]);
    setShowSuggestions(false);
    onClearInput && onClearInput();
  };

  const handleFocus = () => {
    setIsFocused(true);
    setShowSuggestions(true);

    if (!userInput) {
      setFilteredOptions(options);
    }
  };

  const handleBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
      setShowSuggestions(false);
    }, 100);
  };

  return (
    <div className={`relative w-full max-w-md mx-auto mb-[10px] ${className}`}>
      <label htmlFor={id} className={`mb-[5px] text-[13px] font-bold ${error ? 'text-red-500' : ''}`}>
        {label}
      </label>
      <div className="relative">
        <input
          role="combobox"
          type="text"
          className={`w-full p-2 pr-10 border rounded-lg focus:outline-none
            ${!error ? 'focus:ring-1 focus:ring-blue-500 border-gray-300' : 'border-red-500 text-red-500 bg-red-100'}`}
          id={id}
          disabled={disabled}
          onChange={handleOnChange}
          value={userInput}
          placeholder={placeholder || ''}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {userInput && (
          <button className="absolute inset-y-0 right-0 flex items-center pr-3 text-gray-500" onClick={clearInput}>
            ✕
          </button>
        )}
      </div>
      {error && errorMessage && <p className="text-red-500 ">{errorMessage}</p>}
      {showSuggestions && isFocused && (
        <ul
          role="list"
          className="absolute z-50 w-full mt-1 overflow-y-auto bg-white border border-gray-300 rounded-lg max-h-60"
        >
          {filteredOptions.length > 0 ? (
            userInput ? (
              filteredOptions.map((option, index) => (
                <li
                  role="listitem"
                  key={index}
                  className="p-2 cursor-pointer hover:bg-gray-100"
                  onMouseDown={() => onClick(option)}
                >
                  {option.label}
                </li>
              ))
            ) : (
              options.map((option, index) => (
                <li key={index} className="p-2 cursor-pointer hover:bg-gray-100" onMouseDown={() => onClick(option)}>
                  {option.label}
                </li>
              ))
            )
          ) : (
            <li role="listitem" className="p-2 text-gray-500">
              No suggestions found
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default Autocomplete;
