import { BonusPaymentsTableColumns } from './types';
import { NetworksData } from 'constants/networks';

export const tableColumns: BonusPaymentsTableColumns[] = [
  { columnName: 'ID', key: 'id' },
  { columnName: 'Network', key: 'network' },
  { columnName: 'Network Merchant', key: 'networkMerchant' },
  { columnName: 'Application Name', key: 'applicationName' },
  { columnName: 'Created Date', key: 'createdDate' },
  { columnName: 'Modified Date', key: 'modifiedDate' },
  { columnName: 'Modified Author', key: 'modifiedAuthor' },
  { columnName: 'Sale Amount', key: 'saleAmount' },
  { columnName: 'Commission Amount', key: 'commissionAmount' },
  { columnName: 'Event Date', key: 'eventDate' },
  { columnName: 'Device ID', key: 'deviceId' },
  { columnName: 'Merchant Order ID', key: 'merchantOrderId' },
  { columnName: 'Tracking Code', key: 'trackingCode' },
  { columnName: 'Bonus Amount', key: 'bonusAmount' },
  { columnName: 'USD Bonus Amount', key: 'usdBonusAmount' },
  { columnName: 'Status', key: 'status' },
];

export const currencyOptions = [
  {
    label: 'USD - United States Dollar',
    value: 'USD',
  },
  {
    label: 'EUR - Euro',
    value: 'EUR',
  },
  {
    label: 'JPY - Japanese Yen',
    value: 'JPY',
  },
  {
    label: 'GBP - British Pound Sterling',
    value: 'GBP',
  },
  {
    label: 'AUD - Australian Dollar',
    value: 'AUD',
  },
  {
    label: 'CAD - Canadian Dollar',
    value: 'CAD',
  },
  {
    label: 'CHF - Swiss Franc',
    value: 'CHF',
  },
  {
    label: 'CNY - Chinese Yuan Renminbi',
    value: 'CNY',
  },
  {
    label: 'SEK - Swedish Krona',
    value: 'SEK',
  },
  {
    label: 'NZD - New Zealand Dollar',
    value: 'NZD',
  },
];

export const networkOptions = Object.entries(NetworksData).map(([networkName, networkData]) => ({
  label: `${networkData.id} - ${networkName}`,
  value: networkData.id,
}));
