import React from 'react';
import AddBonusPaymentsForm from './AddBonusPaymentsForm';
import { FormattedNetworkMerchantSearchResult } from './types';

interface AddBonusPaymentsProps {
  fetchBonusPaymentsList: () => void;
  fetchNetworkMerchantSearchResults: (
    searchQuery: string,
    networkId: number,
    setSearchResults: (results: FormattedNetworkMerchantSearchResult[]) => void,
    setIsLoading: (loading: boolean) => void,
  ) => Promise<void>;
}

const AddBonusPayments = ({ fetchBonusPaymentsList, fetchNetworkMerchantSearchResults }: AddBonusPaymentsProps) => {
  return (
    <div className="flex flex-col flex-item grow">
      <h2 className="self-center">Add Bonus Payments</h2>
      <AddBonusPaymentsForm
        fetchBonusPaymentsList={fetchBonusPaymentsList}
        fetchNetworkMerchantSearchResults={fetchNetworkMerchantSearchResults}
      />
    </div>
  );
};

export default AddBonusPayments;
